import { Container } from "react-bootstrap";
import CustomButton from "../CustomButton";
import HeadingWithBgImage from "../HeadingWithBgImage";

const EmergencyHeadline = ({
  withoutBgImageHeading,
  description,
  headline,
  telephoneNumber,
  anchorTextEnd,
  showAnchorCall,
  AppointmentButtonHeadline,
  centerButton,
  media,
  headlineLarge,
  src,
}) => {
  const handleBooking = () => {
    window.open(`${src}`, "_blank");
  };
  return (
    <div>
      <Container>
        <HeadingWithBgImage
          withoutBgImageHeading={withoutBgImageHeading}
          media={media}
          description={description}
          headline={headline}
          telephoneNumber={telephoneNumber}
          anchorTextEnd={anchorTextEnd}
          showAnchorCall={showAnchorCall}
          headlineLarge={headlineLarge}
        />
        {AppointmentButtonHeadline && <CustomButton
          headline={AppointmentButtonHeadline}
          onClick={handleBooking}
          centerButton={centerButton}
        />}
      </Container>
    </div>
  );
};

export default EmergencyHeadline;
