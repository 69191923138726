import NavBar from "../Navbar";
import styles from "./Layout.module.css";
import Footer from "../footer";
import currentLocation from "../../pages/data"
import { renderComponent } from "../../utils/renderComponent";


const Layout = (props) => {
  const location = currentLocation
  const shared = currentLocation.shared || {};

  return (
    <>
    {shared && 
        renderComponent({ 
          component: shared.header.name,
          media: location.media,
          src:shared.header.src,
          name:location.name
        })
      }
       {props.children}
      <div className={styles.footerContaner}>
      {shared && 
          renderComponent({ 
            component: shared.footer.name, 
            src:shared.footer.src,
            refersrc:shared.footer.refersrc,
            title:location.title
          })
        }
      </div>
    </>
  );
};

export default Layout;
