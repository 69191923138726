import Star from "../../assets/images/testimonialStar.svg";
import styles from "./UserReviews.module.css";
import { Col, Row } from "react-bootstrap";

const UserReviews = ({ userReviews }) => {
  return (
    <Row>
      {userReviews &&
        userReviews.map((review) => (
          <Col
            lg={4}
            sm={12}
            key={review.id}
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <div className={styles.desktopStar}>
              {Array(review.stars)
                .fill()
                .map((_, i) => (
                  <img src={Star} alt="star" key={i} />
                ))}
            </div>
            <p className={styles.title}>{review.title}</p>
            <p className={styles.desc}>{review.description}</p>
            <div className={styles.reviewStarparent}>
              <div>
                <img src={review.reviewerImage} alt="reviewerImage" />
              </div>
              <div>
                <p className={styles.name}>{review.reviewerName}</p>
                <div className={styles.mobileStar}>
                  {Array(review.stars)
                    .fill()
                    .map((_, i) => (
                      <img src={Star} alt="reviewerImage" key={i} />
                    ))}
                </div>
              </div>
            </div>
          </Col>
        ))}
    </Row>
  );
};

export default UserReviews;
