// componentRegistry.js
import MediaOverlay from "./MediaOverlay";
import ServiceCard from "./ServiceCard";
import HeadingWithBgImage from "./HeadingWithBgImage";
import YourMap from "./YourMap";
import GetInTouch from "./GetInTouch";
import Carousel from "./Carousel";
import LeftImage from "./LeftImage"
import SimpleImageGallery from "./SimpleImageGallery"
import YourTeam from "./YourTeam"
import UserReviews from "./UserReviews"
import RightImage from "./RightImage"
import PriceCard from "./PriceCard"
import ImageWithDescription from "./ImageWithDescription"
import FinanceCalculator from "./Calculator"
import DownloadableLinks from "./DownloadableLinks"
import BlogCards from "./BlogCards";
import EmergencyHeadline from "./EmergencyHeadline"
// import CustomButton from "./CustomButton";
import LeadForm from "./LeadForm"
import BeforeAfterTreatment from "./BeforeAfterTreatment"
import HtmlContent from "./HtmlContent"
import Faq from "./Faq"
import Navbar from "./Navbar";
import footer from "./footer";
// Export components as an object
const componentRegistry = {
  MediaOverlay,
  ServiceCard,
  HeadingWithBgImage,
  YourMap,
  GetInTouch,
  Carousel,
  LeftImage,
  SimpleImageGallery,
  YourTeam,
  UserReviews,
  RightImage,
  PriceCard,
  ImageWithDescription,
  FinanceCalculator,
  DownloadableLinks,
  BlogCards,
  // CustomButton,
  EmergencyHeadline,
  LeadForm,
  BeforeAfterTreatment,
  HtmlContent,
  Faq,
  footer,
  Navbar
};

export default componentRegistry;
