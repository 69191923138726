import React, { createContext, useContext, useEffect } from 'react';
import currentLocation from "../pages/data"

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

  const theme = currentLocation.colors || {}; 

  useEffect(() => {
    const root = document.documentElement; // Get the root element (:root)
    // mainAccent test color #c0c0c0
    root.style.setProperty('--main-accent-color', theme.mainAccentColor);
     // mainAccentdark test color #00FF00
    root.style.setProperty('--main-accent-dark', theme.mainAccentDark);
     // bgcolor test color #9E0007
    root.style.setProperty('--bg-color', theme.bgColor);
    // headline test color #FF8000
    root.style.setProperty('--headline-color', theme.headlineColor);
    // subheadline test color #FF00FF
    root.style.setProperty('--subheadline-color', theme.subHeadlineColor);
    // content test color #8000FF
    root.style.setProperty('--content-color', theme.content);


  }, [theme]);

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
