import styles from "./Trusted.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";
import rightArrow from "../../assets/images/right-arrow.png";
import HtmlContent from "../HtmlContent";

const LeftImage = ({ media, description,htmlContent, isIconButton = false }) => {
  const navigate = useNavigate();
  const temp = 10;
  const handlePrimaryAction = () => {
    navigate("/contact-us");
  };
  return (
    <div>
      <Container>
        <Row className={styles.section2}>
          <Col className={styles.headerGroup2}>
            <img src={media} alt="leftimage"/>
          </Col>
          <Col>
          {description ? (
            <p className={styles.paragraph}>{description}</p>
          ):(
            <HtmlContent htmlContent={htmlContent} />
          )}
            
            <div className={styles.buttonContainer}>
              <CustomButton
                headline={"Let’s Start"}
                onClick={handlePrimaryAction}
                className={styles.customButtonFirst}
              />
              {isIconButton && (
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    cursor: "pointer",
                    width: "-webkit-fill-available",
                  }}
                  className={styles.appointment}
                  onClick={() => navigate("/about-us")}
                >
                  <button className={styles.button2}>Read more</button>
                  <img
                    src={rightArrow}
                    alt="rightArrow"
                    height={11}
                    className={styles.rightArrowAlign}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LeftImage;
