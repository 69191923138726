import { Route, Routes, useLocation } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import currentLocation from "../pages/data";

// Default SEO config
const defaultSeo = {
  title: 'Oaklands Dental - Quality Dental Care in Huddersfield',
  description: 'Providing quality dental care with a focus on patient satisfaction and innovative treatment options in Huddersfield.',
  canonical: 'https://oaklandsdentalhudds.co.uk',
  keywords: 'dentist, dental care, Oaklands Dental, dental clinic, Huddersfield dental treatment',
};

// Lazy load the components
const Home = lazy(() => import("../pages/home"));
const AboutUs = lazy(() => import("../pages/about-us/"));
const Services = lazy(() => import("../pages/services"));
const ContactUs = lazy(() => import("../pages/contact-us"));
const Team = lazy(() => import("../pages/team"));
const Testimonials = lazy(() => import("../pages/testimonials"));
const Documents = lazy(() => import("../pages/documents"));
const Pricing = lazy(() => import("../pages/pricing"));
const Blogs = lazy(() => import("../pages/blogs"));
const ModernSlavery = lazy(() => import("../pages/modern-slavery"));
const Emergency = lazy(() => import("../pages/emergency"));
const ServiceDetail = lazy(() => import("../pages/ServiceDetail"));
const BlogDetail = lazy(() => import("../pages/BlogDetail"));

const AllRoutes = () => {
  const location = useLocation();
  const SEO_CONFIG = currentLocation.SEO_CONFIG || {};
  const currentSeo = SEO_CONFIG[location.pathname] || defaultSeo;
  console.log("🚀 ~ AllRoutes ~ currentSeo:", currentSeo)

  // Extract address details with checks
  const addressParts = currentLocation.data?.address?.split(",") || [];
  const streetAddress = addressParts[0]?.trim() || "";
  const addressLocality = addressParts[1]?.trim() || "";
  const postalCode = addressParts[2]?.trim() || "";

  return (
    <>
      <Helmet>
        {/* Title and Description */}
        <title>{currentSeo.title}</title>
        <meta name="description" content={currentSeo.description} />
        <meta name="keywords" content={currentSeo.keywords} />
        <link rel="canonical" href={currentSeo.canonical} />
        <meta property="og:title" content={currentSeo.title} />
        <meta property="og:description" content={currentSeo.description} />
        <meta property="og:url" content={currentSeo.canonical} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={currentLocation.media?.headerLogo} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={currentSeo.title} />
        <meta name="twitter:description" content={currentSeo.description} />
        <meta name="twitter:image" content={currentLocation.media?.headerLogo} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Dentist",
            "name": currentLocation.title || "Oaklands Dental Huddersfield",
            "url": currentSeo.canonical,
            "logo": currentLocation.media?.headerLogo,
            "sameAs": [
              "https://www.facebook.com/oaklandsdentalhuddersfield",
              "https://twitter.com/oaklandsdental",
              "https://www.instagram.com/oaklandsdentalhuddersfield/"
            ],
            "description": currentSeo.description,
            "address": {
              "@type": "PostalAddress",
              "streetAddress": streetAddress,
              "addressLocality": addressLocality,
              "postalCode": postalCode,
              "addressCountry": "UK"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": currentLocation.data?.phone,
              "contactType": "Customer Service"
            }
          })}
        </script>
      </Helmet>

      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/about-us/team" element={<Team />} />
          <Route path="/about-us/testimonials" element={<Testimonials />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:serviceName" element={<ServiceDetail />} />
          <Route path="/information" element={<Pricing />} />
          <Route path="/information/documents" element={<Documents />} />
          <Route path="/information/privacy" element={<Documents />} />
          <Route path="/information/finance" element={<Pricing />} />
          <Route path="/information/pricing" element={<Pricing />} />
          <Route path="/information/nhs" element={<Pricing />} />
          <Route path="/information/tabeo" element={<Pricing />} />
          <Route path="/information/forpatient" element={<Pricing />} />
          <Route path="/information/modern-slavery" element={<ModernSlavery />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:blogName" element={<BlogDetail />} />
          <Route path="/emergency" element={<Emergency />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AllRoutes;
