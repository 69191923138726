import { Col, Container, Row } from "react-bootstrap";
import styles from "./Footer.module.css";
import ContactCard from "../ContactCard";
import { useNavigate } from "react-router-dom";
import Cqc from "../../assets/images/footer/cqc.svg";
import Nhs from "../../assets/images/footer/nhs.svg";
import Gdc from "../../assets/images/footer/gdc.jpeg";
import HoursOfOperation from "../HoursOfOperations";
import CustomButton from "../CustomButton";
import { useTheme } from "../../context/ThemeContext"; // Import the useTheme hook
import currentLocation from "../../pages/data";

const Footer = ({src,refersrc,title}) => {
  // const location = Locations[0];
  // const ContactCardData = location.data;
  const ContactCardData = currentLocation.data || {};
  const theme = useTheme(); // Access the theme colors

  const navigate = useNavigate();

  const handleNavigation = (path, external = false) => {
    if (external) {
      window.open(path, "_blank"); // For external links
    } else {
      navigate(path); // For internal navigation
    }
  };

  const footerLinks = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/about-us" },
    { label: "Meet The Team", path: "/about-us/team" },
    { label: "Contact Us", path: "/contact-us" },
    { label: "Privacy Policy", path: "/information/privacy" },
    { label: "Pricing", path: "/information/pricing" },
    { label: "Information For Patients", path: "/information/forpatient" },
    { label: "What We Offer", path: "/information/pricing" },
    { label: "Modern Slavery Policy", path: "/information/modern-slavery" },
    // {
    //   label: "Book Now",
    //   path: "https://oaklands-dental.dentr.net/book",
    //   external: true,
    // },
  ];
  const handleBooking = (src) => {
    window.open(src, "_blank");
  };
  const handleHome = () => {
    navigate("/");
  };
  return (
    <>
      <footer>
        <div className={styles.contactCard}>
          <ContactCard data={ContactCardData} />
        </div>
        <div className={styles.footerTopWhiteSpace}></div>
        <Container
          fluid
          className={styles.contentMain}
          style={{ background: "#1f45b105", paddingTop: 30 }}
        >
          <Row>
            <Col lg={2} />
            <Col lg={4} sm={12}>
              <HoursOfOperation />
            </Col>
            {/* for mobile view */}
            <Col
              lg={4}
              sm={12}
              className={`${styles.bookingButton} ${styles.mobileBookingButton}`}
            >
              <CustomButton
                headline="Book an Appointment"
                onClick={handleBooking}
                className={styles.customButton}
              />
              <div>
                <ul className={styles.footerLink}>
                  <li>
                    <a style={{ cursor: "pointer" }}>Refer Your Patient</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} sm={12} className={styles.mobileCenter}>
              <p style={{ fontWeight: "bold", color: theme.content }}>
                Affiliated
              </p>
              <div>
                <img
                  height={50}
                  style={{ cursor: "pointer", marginTop: 20 }}
                  src={Cqc}
                  alt="footerLogo1"
                  onClick={() =>
                    window.open(
                      "https://www.cqc.org.uk/guidance-providers/dentists",
                      "_blank"
                    )
                  }
                />
              </div>
              <div>
                <img
                  height={50}
                  style={{ cursor: "pointer", marginTop: 20 }}
                  src={Nhs}
                  alt="nhs"
                  onClick={() => window.open("https://www.nhs.uk/", "_blank")}
                />
              </div>
              <div>
                <img
                  height={70}
                  style={{ cursor: "pointer", marginTop: 20 }}
                  src={Gdc}
                  alt="gdc"
                  onClick={() =>
                    window.open("https://www.gdc-uk.org/", "_blank")
                  }
                />
              </div>
            </Col>
          </Row>
          {/* for desktop */}
          <Row>
            <Col lg={4} />
            <Col lg={4} sm={12} className={styles.hideOnMobile}>
              <CustomButton
                headline="Book an Appointment"
                onClick={() => handleBooking(src)}
                className={styles.customButton}
              />
              <div style={{ marginTop: 20 }}>
                <ul className={styles.footerLink}>
                  <li>
                    <a
                      href={refersrc}
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Recommended for security reasons
                      style={{ cursor: "pointer",textDecoration: "none",color: "inherit" }}
                    >
                      Refer Your Patient
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col />
          </Row>
          <Row>
            <Col lg={2} />
            <Col lg={8} sm={12} className={styles.footerContent}>
              <div className={styles.contentMain}>
                <ul className={styles.footerLink}>
                  <Container fluid>
                    <Row>
                      {footerLinks.map((link, index) => (
                        <Col
                          key={index}
                          lg={4} // 3 columns on large screens
                          sm={6} // 2 columns on small and medium screens
                          xs={6} // Full width on extra small screens
                        >
                          <li>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleNavigation(
                                  link.path,
                                  link.external || false
                                )
                              }
                            >
                              {link.label}
                            </a>
                          </li>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                </ul>
              </div>
            </Col>
            <Col lg={2} />
          </Row>
        </Container>
      </footer>
      <div className={styles.copywrightText}>
        Copyright 2024 All Rights Reserved by{" "}
        <a
          // href="https://oaklandsdentalhudds.co.uk/What%20we%20offer.html#"
          onClick={handleHome}
          style={{
            color: "#52575D",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {" "}
          {title}
        </a>
        .
      </div>
    </>
  );
};

export default Footer;
