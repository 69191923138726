import { Container, Row, Col } from "react-bootstrap";
import styles from "./SimpleImageGallery.module.css";
const SimpleImageGallery = ({ media, noBgColor = true }) => {
  return (
    <Container fluid className={noBgColor ? styles.noBgColor : styles.bgColor}>
      <Container>
        <Row>
          {media &&
            media.map((service, index) => (
              <Col sm key={index}>
                <img
                  src={service.image}
                  style={{ width: "100%", height: "auto" }}
                  alt="simpleImageGallery"
                />
              </Col>
            ))}
        </Row>
      </Container>
    </Container>
  );
};

export default SimpleImageGallery;
