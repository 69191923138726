import { Container, Row, Col } from "react-bootstrap";
import styles from "./ServiceCard.module.css"; // Assuming you have styles for your component
import { useNavigate } from "react-router-dom";

const ServiceCard = ({
  services,
  noBgColor = true,
  noClickableCard = true,
}) => {
  const navigate = useNavigate();

  return (
    <div className={noBgColor ? styles.noBgColor : styles.solutionsContainer0}>
      <Container className={styles.servicesParent}>
        <Row>
          {services &&
            services.map((service, index) => (
              <Col
                key={index}
                sm={12}
                lg={4}
                className={noClickableCard ? "" : styles.solutionsCol}
                onClick={() => {
                  if (!noClickableCard) {
                    navigate(`/services/${service?.slug}`);
                  }
                }} // Disable navigation if noBgColor is true
              >
                <div className={styles.solutionIcons}>
                  <img
                    src={service.image}
                    height={116}
                    alt={`${service.headline}-icon`}
                  />
                </div>
                <Container>
                  <h3 className={styles.solutionsTitle}>{service.headline}</h3>
                  <h4 className={styles.sectionText}>
                    <ul>
                      {service?.bullets?.map((bullet, bulletIndex) => (
                        <li key={bulletIndex}>{bullet}</li> // Rendering bullet points dynamically
                      ))}
                    </ul>
                  </h4>
                  <h5 className={styles.sectionDescription}>
                    {service.description}
                  </h5>
                </Container>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServiceCard;
