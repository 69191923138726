import BrandLogo from "../../assets/images/footer/Oakland_Logo.png";
import LocationIcon from "../../assets/images/footer/location.png";
import PhoneIcon from "../../assets/images/footer/phone.png";
import EmailIcon from "../../assets/images/footer/emailIcon.png";
import styles from "./ContactCard.module.css";
import { useNavigate } from "react-router-dom";

export default function ContactCard({data}) {
  const navigate = useNavigate();
  return (
    <div className={styles.footerContactCard}>
      <img
        src={data.media}
        alt="Brand-logo"
        className={styles.brandLogo}
        onClick={() => navigate("/")}
      />
      <div style={{ marginTop: 30 }} />
      <div className={styles.address}>
        <div style={{ display: "flex", gap: 15 }}>
          <div>
            <img
              src={LocationIcon}
              alt="Location icon"
              className={styles.cardIcons}
            />
          </div>
          <div style={{ marginTop: 2 }}>
            <p className={styles.contactCardHeading}>Address</p>

            <span className={styles.addressDetails}>
             {data.address}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.address}>
        <div style={{ display: "flex", gap: 15 }}>
          <div>
            <img
              src={PhoneIcon}
              alt="Location icon"
              className={styles.cardIcons}
            />
          </div>
          <div style={{ marginTop: 2 }}>
            <p className={styles.contactCardHeading}>Phone</p>
            <span className={styles.addressDetails}>{data.phone}</span>
          </div>
        </div>
      </div>

      <div className={styles.address}>
        <div style={{ display: "flex", gap: 15 }}>
          <div>
            <img
              src={EmailIcon}
              alt="Location icon"
              className={styles.cardIcons}
            />
          </div>
          <div style={{ marginTop: 2 }}>
            <p className={styles.contactCardHeading}>Email</p>
            <span className={styles.addressDetails}>
              {data.email}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
